module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"愛苗行動社區服務中心 Loving Kids Community","short_name":"愛苗行動 Loving Kids","description":"愛苗行動社區服務中心 (簡稱「愛苗」) 為非牟利慈善團體 (編號:91/13088)。其使命是令家境有困難及單親家庭的孩子能夠得到應有的照顧幫助，除提供適當學業輔導外，更注重培養孩子的德育品格，令孩子們能於「學業與品格並重」的環境下，健康地成長發展。","lang":"zh-HK","start_url":"/","background_color":"#ffffff","theme_color":"#e03a3c","display":"minimal-ui","icon":"content/assets/favicon/company_logo.png","localize":[{"start_url":"/en/","lang":"en-US","name":"Loving Kids Community","short_name":"Loving Kids","description":"Loving Kids Community Service Co. Ltd. (91/13088) is a social service centre providing child-day-caring for single-parent families, and providing job opportunities to low-educated and low income group."},{"start_url":"/zh-CN/","lang":"zh-CN","name":"爱苗行动社区服务中心 Loving Kids Community","short_name":"爱苗行动 Loving Kids","description":"爱苗行动社区服务中心 (简称“爱苗”) 为非牟利慈善团体 (编号:91/13088)。其使命是令家境有困难及单亲家庭的孩子能够得到应有的照顾帮助，除提供适当学业辅导外，更注重培养孩子的德育品格，令孩子们能于“学业与品格并重”的环境下，健康地成长发展。"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"e22380e2c864ae5588175af579a3d1a2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["zh-HK","zh-CN","en"],"defaultLanguage":"zh-HK","siteUrl":"http://lovingkids.org.hk/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
