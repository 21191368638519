exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-governance-js": () => import("./../../../src/pages/about-us/governance.js" /* webpackChunkName: "component---src-pages-about-us-governance-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-courses-ascp-js": () => import("./../../../src/pages/courses/ascp.js" /* webpackChunkName: "component---src-pages-courses-ascp-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-courses-interest-classes-js": () => import("./../../../src/pages/courses/interest-classes.js" /* webpackChunkName: "component---src-pages-courses-interest-classes-js" */),
  "component---src-pages-courses-tutoring-classes-js": () => import("./../../../src/pages/courses/tutoring-classes.js" /* webpackChunkName: "component---src-pages-courses-tutoring-classes-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-enquiry-member-points-js": () => import("./../../../src/pages/enquiry-member-points.js" /* webpackChunkName: "component---src-pages-enquiry-member-points-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-membership-js": () => import("./../../../src/pages/join-membership.js" /* webpackChunkName: "component---src-pages-join-membership-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-media-article-js": () => import("./../../../src/pages/media/article.js" /* webpackChunkName: "component---src-pages-media-article-js" */),
  "component---src-pages-media-event-highlights-js": () => import("./../../../src/pages/media/event-highlights.js" /* webpackChunkName: "component---src-pages-media-event-highlights-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-media-news-js": () => import("./../../../src/pages/media/news.js" /* webpackChunkName: "component---src-pages-media-news-js" */),
  "component---src-pages-publications-and-forms-annual-reports-js": () => import("./../../../src/pages/publications-and-forms/annual-reports.js" /* webpackChunkName: "component---src-pages-publications-and-forms-annual-reports-js" */),
  "component---src-pages-publications-and-forms-audit-reports-js": () => import("./../../../src/pages/publications-and-forms/audit-reports.js" /* webpackChunkName: "component---src-pages-publications-and-forms-audit-reports-js" */),
  "component---src-pages-publications-and-forms-forms-js": () => import("./../../../src/pages/publications-and-forms/forms.js" /* webpackChunkName: "component---src-pages-publications-and-forms-forms-js" */),
  "component---src-pages-publications-and-forms-index-js": () => import("./../../../src/pages/publications-and-forms/index.js" /* webpackChunkName: "component---src-pages-publications-and-forms-index-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

